@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Manrope", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
}

:root {
  --primar_color: #d95e73;
  --white: #ffffff;
  --black: #000000;
  --para_color1: #302f3e;
  --para_color2: #a3a3a3;
  --para_color3: #6b6c78;
  --border_color1: #de6b7d;
  --border_color2: #d85e72;
  --bg_color1: #b8b8ce;
  --bg_color2: #e3e3e3;
  --bg_color3: #707070;
  --dot_color1: #e2e2e2;
  --active_dot: #d85e72;
  --head_color: #1f1e21;
  --box_shadow1: 4px 4px 8px #a5a5a542;
  --box_shadow2: 0px 2px 6px #b8b8ce5d;
  --link_color1: #412798;
  --link_color2: #303079;
}

.primary_btn {
  background: var(--primar_color) !important;
  color: var(--white) !important;
  font-size: 16px !important;
  border-radius: 6px !important;
  transition: all 0.5s !important;
}
.primary_btn:hover {
  background-color: transparent !important;
  color: var(--primar_color) !important;
  border-color: var(--primar_color) !important;
  transition: all 0.5s;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0px rgb(13 110 253 / 0%) !important;
}

.card {
  background: #fefffb !important;
  box-shadow: 0px 0px 6px rgba(98, 53, 87, 0.078) !important;
  border-radius: 6px !important;
  border: none !important;
}

a {
  text-decoration: none !important;
}

.form-control:focus {
  border-color: #d0d0d0;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%) !important;
}

.dis-none {
  display: none !important;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.user_qus_section {
  display: block;
}

.user_qus_section > div > div {
  width: 100%;
}
