/* Registration Style */
.register_section {
  background: #fff8f9;
  height: 100vh;
}
.sign_left img {
  max-width: 100%;
  object-fit: contain;
}
.logo_block {
  padding: 25px 0px 0px 33px;
}
.logo_block img {
  object-fit: cover;
  width: 108px;
  height: 23px;
}
.sign_right_head h1 {
  color: var(--primar_color);
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
}
.sign_right_head p {
  color: var(--para_color1);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.8px;
  font-weight: 300;
  margin-bottom: 0px;
}

.sign_right_card {
  padding: 40px 52px;
  margin-top: 42px;
}
.other_sign h4 {
  color: var(--para_color2);
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.41px;
  font-weight: 300;
}
.sign_right_block {
  width: 435px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.other_sign ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}
.other_sign ul li {
  flex: 1;
}
.other_sign ul li a,
.other_sign ul li button {
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 18px 34px;
  color: var(--para_color2);
  font-size: 14px;
  transition: all 0.5s;
  height: 60px;
  background: transparent;
}
.other_sign ul li a img,
.other_sign ul li button img {
  margin-right: 10px;
  max-width: 100%;
  object-fit: contain;
  width: 28px;
  height: 28px;
}
.other_sign ul li a:hover,
.other_sign ul li button:hover {
  border: 1px solid var(--border_color1);
  background: #fff8f9;
  transition: all 0.5s;
}

.horizontal_line {
  color: #c7c7c7;
  font-size: 14px;
  text-align: center;
  position: relative;
  margin: 0;
}
.horizontal_line::before {
  content: "";
  border-bottom: 1px solid #efefef;
  width: 40%;
  position: absolute;
  left: 0;
  top: 50%;
}
.horizontal_line::after {
  content: "";
  border-bottom: 1px solid #efefef;
  width: 40%;
  position: absolute;
  right: 0;
  top: 50%;
}
.sign_right_form {
  margin-top: 24px;
}
.sign_right_form form .input_form input {
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  height: 48px;
  color: var(--para_color2);
  font-size: 14px;
  padding: 15px;
  background: transparent;
  font-weight: 300;
}
.form_btn button {
  width: 100%;
  padding: 15px 0px;
  transition: all 0.5s;
}
.form_btn button:hover {
  border: 1px solid var(--primar_color);
  background: transparent !important;
  color: var(--primar_color) !important;
  transition: all 0.5s;
}
.form_btn {
  margin: 34px 0px 15px;
}
.already_acc {
  font-size: 14px;
  letter-spacing: 0.28px;
  font-weight: 300;
  color: var(--para_color2);
  margin-bottom: 0px;
  text-align: center;
  line-height: 22px;
}
.already_acc a {
  color: #412798;
  font-weight: 600;
  transition: all 0.5s;
}
.already_acc a:hover {
  color: var(--primar_color);
  transition: all 0.5s;
}
.sign_section > .row {
  height: calc(100vh - 51px);
}
.sign_left {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_section > div {
  justify-content: space-between;
}
.otp_main {
  margin: 0px !important;
}
.otp_main h4 {
  color: #302f3e;
  font-size: 16px;
  line-height: 34px;
  margin: 0px 0px 40px;
}
.otp_btn button {
  padding: 13px 0px;
}
.otp_btn {
  margin: 50px 0px 15px;
}
.resend_otp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.resend_otp a {
  color: #412798;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  transition: all 0.5s;
}
.resend_otp a:hover {
  color: var(--primar_color);
  transition: all 0.5s;
}
.otp_block input:focus {
  border: 1px solid #de6b7d !important;
}
.sign_right_otp > .sign_right_card {
  margin-top: 28px;
}
.mt28 {
  margin-top: 28px;
}

/* Quiz Style */
.header_logo a {
  display: inline-block;
}
.header_logo a img {
  max-width: 100%;
  width: 110px;
  object-fit: contain;
}
.back_btn a {
  letter-spacing: 0.64px;
  color: var(--para_color3);
  display: flex;
  align-items: center;
  font-size: 16px;
}
.back_btn a img {
  margin-right: 12px;
}
.quiz_head {
  background: var(--white);
  box-shadow: var(--box_shadow1);
  padding: 18px 0px;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.already_usr p {
  margin: 0px;
  color: var(--para_color3);
  font-size: 16px;
  letter-spacing: 0.32px;
  font-weight: 300;
  text-align: right;
}
.already_usr p a {
  display: inline-block;
  color: var(--link_color1);
  font-weight: 600;
}

.already_usr,
.back_btn {
  flex: 1;
}
.start_content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.start_content h4 {
  color: var(--head_color);
  font-size: 30px;
  text-align: center;
  line-height: 42px;
  letter-spacing: 1.2px;
  font-weight: 600;
  margin: 0px 0px 32px;
}
.start_btn {
  padding: 17px 99px;
  letter-spacing: 0.29px;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
}
.start_section {
  height: calc(100vh - 65px);
}
.start_section .start_block {
  margin: 52px 0px 30px;
}
.progress_section {
  background: rgba(184, 184, 206, 0.08);
  padding: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress_block ul {
  list-style: none;
  display: flex;
  margin: 0px;
}
.progress_block ul li span.qus_indicate {
  width: 24px;
  height: 24px;
  border: 3px solid var(--dot_color1);
  background: transparent;
  display: inline-block;
  border-radius: 50%;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
}
.progress_block ul li span.circle_dots {
  width: 8px;
  height: 8px;
  border: 1px solid var(--dot_color1);
  background: var(--dot_color1);
  display: inline-block;
  border-radius: 50%;
  margin-right: 24px;
}
.progress_block ul li {
  display: flex;
  align-items: center;
}
.active_dot {
  background: transparent !important;
  border: 1px solid var(--border_color2) !important;
}
.active_dot_fill {
  background: var(--border_color1) !important;
}
.active_main_dot {
  background: var(--border_color1) !important;
  width: 32px !important;
  height: 32px !important;
  border: 1px solid var(--border_color1) !important;
}
.active_main_completed {
  width: 20px !important;
  height: 20px !important;
  background: var(--border_color1) !important;
  border: 1px solid var(--border_color1) !important;
}
.active_main_completed::before {
  content: url(../public/images/icons/tick.svg);
  position: relative;
  bottom: 2px;
  left: 1px;
}
.quiz_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quiz_btn_section {
  padding: 18px 0px 27px;
  border-top: 1px solid rgba(112, 112, 112, 0.17);
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 20;
}
.quiz_btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.quiz_btn button {
  padding: 14px 61px;
  font-size: 14px !important;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
}
.quiz_btn button > img {
  transition: all 0.3s ease-in-out;
  margin-left: 9px;
}
.quiz_btn button:hover {
  background: var(--primar_color) !important;
  color: var(--white) !important;
}
.quiz_btn button:hover > img {
  transform: translateX(10px);
}

/* quiz Radio Button */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}
.checkbox-booking:checked + label,
.checkbox-booking:not(:checked) + label {
  position: relative;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  padding: 21px 25px;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  color: #6b6c78;
  background-color: var(--white);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  box-shadow: 0px 2px 6px rgba(184, 184, 206, 0.365);
}

.checkbox-booking:checked + label,
label.for-checkbox-booking:hover {
  border: 2px solid var(--primar_color);
}
.checkbox-booking:not(:checked) + label {
  border: 2px solid transparent;
}
label.for-checkbox-booking .text {
  transition: all 0.3s ease-in-out;
}
label.for-checkbox-booking:hover .text {
  color: var(--primar_color);
}
.checkbox-booking:checked + label .text,
.checkbox-booking:not(:checked) + label .text {
  position: relative;
  display: inline-block;
}
.checkbox-booking:checked + label .text {
  opacity: 0.8;
  color: var(--primar_color);
}
.label_width {
  width: 234px;
}
.label_width2 {
  width: 268px;
}
/* End Radio Button */

/* .img_placeholder
{
    width: 200px;
    height: 200px;
} */
.img_placeholder img {
  object-fit: contain;
  max-width: 100%;
}

.quiz_qus_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.quiz_img {
  margin-bottom: 30px;
}
.quiz_pick_block {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.question_content h4 {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 1.12px;
  color: var(--head_color);
  font-weight: 600;
  margin: 0px 0px 40px;
}
.question_content {
  text-align: center;
  width: 920px;
  margin: 0 auto;
}
.pick_section div:last-child label {
  margin: 0px !important;
}
.quiz_info_popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.quiz_modal_body {
  padding: 52px 15px;
}
.quiz_info_txt {
  margin: 20px 0px 48px;
}
.quiz_info_txt h4 {
  margin: 0px;
  font-size: 26px;
  line-height: 38px;
  letter-spacing: 1.04px;
  color: var(--head_color);
  font-weight: 600;
  text-align: center;
  padding: 0px 25px;
}
.modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
  margin: 0 auto !important;
}
.quiz_pick_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz_qus_field {
  width: 456px;
}
.quiz_qus_field input {
  height: 56px;
}

.qus_form {
  margin-bottom: 80px;
}
.label_img_placeholder {
  width: 383px;
  height: 206px;
  background: #ededed;
  border-radius: 6px;
  margin-top: 35px;
}

.img_lable label {
  flex-direction: column;
  font-weight: 600;
  font-size: 20px !important;
}
.more_sec {
  margin-top: 34px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.more_sec a {
  text-decoration: underline !important;
  color: var(--primar_color) !important;
  font-size: 16px;
}
.side_nav_section {
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.side_nav_block {
  background: var(--white);
  width: 488px;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 35px 35px;
  overflow: auto;
}

.side_head_sec h4 {
  color: var(--head_color);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}
.side_head_sec ul {
  list-style: none;
  padding-left: 15px;
  margin: 20px 0px 0px;
}

.side_head_sec ul li::before {
  content: "\2022";
  color: var(--primar_color);
  font-weight: bold;
  display: inline-block;
  width: 1.5em;
  margin-left: -1em;
}
.side_head_sec ul li {
  color: #6b6c78;
  font-size: 14px;
  margin-bottom: 13px;
}

.like_btn_block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 32px;
}
.like_btn_block button {
  border: 1px solid var(--primar_color);
  color: var(--primar_color);
  background: transparent;
  font-size: 16px;
  padding: 13px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.side_body_content {
  margin-top: 50px;
}
.speed_block {
  align-items: center;
}
.speed_block h4 {
  margin: 0px 13px;
  color: #f6c25d;
  font-size: 16px;
}
.speed_block p {
  margin: 0;
  color: #6b6c78;
  opacity: 0.35;
  font-size: 14px;
}
.speed_status_sec {
  margin: 20px 0px;
}
.speed_status_sec ul {
  list-style: none;
  padding-left: 5px;
}
.speed_status_sec ul li {
  background: rgba(91, 95, 147, 0.03);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 14px 17px;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
}

.speed_status_sec ul li div h4 {
  margin: 0;
  color: #6b6c78;
  font-size: 14px;
  line-height: 21px;
}
.speed_status_sec ul li div p {
  margin: 0;
  color: #5b5f93;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
}
.speed_status_sec ul li div:nth-child(1) {
  flex: 0 0 145px;
}
.speed_status_sec ul li div:nth-child(2) {
  flex: 1;
}
.close_nav {
  position: absolute;
  right: 30px;
  top: 30px;
}

.sign_acc_head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  float: right;
  padding: 0px;
}
.sign_acc_head .avatar_img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #de6b7d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign_acc_head .avatar_img p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}
.sign_acc_head p {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #0c1f38;
  font-weight: 500;
}
.mb100 {
  margin-bottom: 100px;
}
.h64 {
  height: 64px;
}
.h74 {
  height: 74px;
}
.graph_portfolio_block {
  background: var(--white);
  box-shadow: 0px 0px 10px rgba(184, 184, 206, 0.212);
  border: 1px solid #d1d0d5;
  padding: 25px 35px 30px;
  position: relative;
  border-radius: 6px;
  width: 100%;
}
.graph_portfolio_block .graph_block {
  padding: 17px 36px;
  background: #f7f7f7;
  height: 316px;
  margin-bottom: 20px;
}

.graph_portfolio_block .graph_table_block {
  padding: 16px 19px;
  background: #f7f7f7;
  border-radius: 6px;
  height: 316px;
  overflow: auto;
}
.portfolio_head h4 {
  color: #1f1e21;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
}

.portfolio_btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 30px;
}
.portfolio_btn button {
  padding: 15px 36px;
}

.portfolio_table {
  list-style: none;
  padding: 40px 0px 0px;
}

.portfolio_table li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.portfolio_table li h4 {
  letter-spacing: 0px;
  color: #1f1e21;
  font-size: 15px;
  line-height: 22px;
  margin: 0px;
}

.portfolio_table li p {
  margin: 0px;
  font-size: 15px;
  line-height: 22px;
  color: #1f1e21;
  font-weight: 600;
}
.portfolio_table li span {
  font-size: 10px;
  line-height: 22px;
  color: #5b5f93;
  font-weight: 600;
}

.note_txt {
  margin-top: 16px;
}
.note_txt p {
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.48px;
  color: #848484;
  margin-bottom: 0px;
  font-weight: 300;
}
.note_txt p a {
  font-size: 13px;
  color: #1f1e21;
  font-weight: bold;
  text-decoration: underline !important;
  margin-left: 10px;
}

.risk_profile_section {
  background: #f5f5f5;
  border-radius: 6px;
}
.risk_profile_header {
  display: flex;
  align-items: center;
  background: #eaeaea;
  padding: 30px 55px;
  border-radius: 6px;
}
.risk_profile_header h4 {
  margin: 0px;
  font-size: 20px;
  line-height: 11px;
  color: #1f1e21;
  font-weight: 600;
  margin-right: 18px;
}
.risk_profile_header span {
  color: #f6c25d;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  margin-left: 12px;
}

.risk_profile_body {
  padding: 40px 70px 42px 55px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.risk_body_head {
  margin-bottom: 17px;
}
.risk_body_head h4 {
  font-size: 14px;
  line-height: 21px;
  color: #6b6c78;
  opacity: 0.57;
  margin: 0px;
}
.risk_balance_section {
  justify-content: space-between;
  margin-top: 45px;
  flex-wrap: wrap;
  gap: 10px;
}
.risk_bal_block h4 {
  font-size: 20px;
  color: #1f1e21;
  margin: 0px 0px 5px;
  font-weight: 600;
}
.risk_bal_block p {
  color: #6b6c78;
  font-size: 14px;
  line-height: 15px;
  margin: 0px;
  font-weight: normal;
}
.risk_bal_block h3 {
  color: #d0d0d0;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  padding: 13px 90px;
  border: 1px dashed #d0d0d0;
  border-radius: 6px;
  margin: 0px 0px 12px;
}
.risk_bal_block p strong {
  color: #1f1e21;
}
.risk_bal_block button {
  padding: 13px 70px;
  font-size: 16px;
  line-height: 26px;
}
.risk_body_list ul {
  padding: 0px;
  list-style: none;
}
.risk_body_list ul li {
  margin-bottom: 10px;
  font-size: 18px;
}
.modal {
  background: rgba(128, 128, 128, 0.7);
}
.modal-content {
  border: none;
}

.pick_section {
  flex-wrap: wrap;
  gap: 25px;
}
.like_btn_block p {
  margin: 0px 20px 0px 0px;
  color: #6b6c78;
  font-size: 14px;
}
.sidebar_risk_head {
  display: flex;
  align-items: center;
  gap: 12px;
}

.investment_head {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.investment_head h4 {
  margin: 0px 15px 0px 0px;
  font-size: 14px;
  color: #6b6c78;
  opacity: 0.57;
  line-height: 21px;
}
.investment_head span {
  display: inline-block;
  font-size: 14px;
  color: #f6c25d;
  line-height: 21px;
  margin-left: 12px;
}
.risk_badge {
  background: #302f3e;
  box-shadow: 0px 2px 6px #b8b8ce5d;
  border: 1px solid #5b5f93;
  border-radius: 0px 6px;
  padding: 5px 24px 7px;
  position: absolute;
  top: 0;
  right: 0;
}
.risk_badge p {
  margin: 0px;
  font-size: 12px;
  line-height: 22px;
  color: var(--white);
}

.risk_outerLine_btn {
  border: 1px solid #707070;
  border-radius: 6px;
  color: #1f1e21;
  font-size: 16px;
  line-height: 22px;
  padding: 11px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.3s ease-in-out;
}
.high_risk_btn {
  color: var(--primar_color);
  border: 1px solid var(--primar_color);
}
.risk_outerLine_btn:hover {
  color: var(--primar_color);
}
.high_risk_btn:hover {
  color: #1f1e21;
}
.h-prefer-block {
  height: 300px;
}
.chart_test {
  width: 100%;
}
.chart_test .apexcharts-title-text {
  display: none;
}
.already_usr {
  position: relative;
}
.already_usr ul.dropdown-menu {
  left: unset !important;
  right: 0 !important;
  top: 50px;
  padding: 0px;
}
.already_usr ul.dropdown-menu li a {
  padding: 10px;
}
.dropdown-toggle::after {
  color: #333333;
}
@media (min-width: 1110px) {
  .risk_profile_section,
  .risk_balance_section,
  .graph_portfolio_block {
    width: 1100px !important;
  }
  .start_block {
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
  }
  .reset_section {
    height: calc(100vh - 197px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
  }
}

@media (max-width: 767px) {
  .start_content {
    width: 100%;
  }
  .dashboard_menu ul {
    flex-direction: column;
  }
  .start_content h4 {
    font-size: 22px;
    line-height: 32px;
  }
  .start_block {
    margin: 15px 0px 30px !important;
  }
  .mob_dis_none {
    display: none;
  }
  .sign_right_block {
    width: 100%;
    padding: 0px 10px;
  }
  .sign_right_card {
    padding: 20px 30px;
    margin: 20px 0px 20px;
  }
  .other_sign ul li a {
    padding: 18px 20px;
  }
  .logo_block {
    padding: 25px 0px 20px 10px;
  }
  .sign_right_head p {
    font-size: 14px;
  }
  .other_sign ul {
    flex-wrap: wrap;
  }
  .sign_section > .row {
    height: calc(100vh - 71px);
  }
  .sign_right_otp > .sign_right_card {
    width: 100%;
  }
  .otp_main h4 {
    font-size: 14px;
    margin: 0px 0px 20px;
  }
  .otp_btn {
    margin: 30px 0px 15px;
  }
  .sign_section {
    margin-top: 0px;
  }
  .progress_block ul li span.circle_dots {
    display: none;
  }
  .question_content {
    width: 100%;
  }
  .label_width {
    width: 100%;
  }
  .quiz_section {
    height: 100%;
  }
  .question_content h4 {
    font-size: 22px;
  }
  .quiz_pick_block {
    align-items: unset !important;
  }
  .pick_section {
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: column;
  }
  .pick_section label {
    width: 100%;
  }
  .pick_section div {
    flex: 1;
  }

  .already_usr p > span {
    display: none;
  }
  .risk_profile_body {
    padding: 20px 30px 2px 30px;
  }
  .risk_bal_block {
    margin-bottom: 15px;
  }

  .graph_portfolio_block .graph_block {
    height: 100%;
  }
  .graph_portfolio_block .graph_table_block {
    height: 100%;
    margin-top: 20px;
  }
  .graph_portfolio_block {
    padding: 25px 15px 30px;
  }
  .graph_portfolio_block .graph_block,
  .graph_portfolio_block .graph_table_block {
    padding: 17px 15px;
  }
  .risk_outerLine_btn {
    font-size: 14px;
    gap: 2px;
    padding: 5px 5px;
  }
  .more_sec {
    gap: 5px;
  }
  .side_nav_block {
    width: 350px;
    padding: 35px 15px 80px;
  }
  .close_nav {
    right: 25px;
    top: 20px;
  }
  .like_btn_block p {
    margin: 0px;
  }
  .note_txt p {
    text-align: center;
    line-height: 22px;
  }
  .mb100 {
    margin-bottom: 50px;
  }
  .risk_balance_section {
    justify-content: center;
  }
  .quiz_modal_body {
    padding: 52px 10px;
  }
  .modal-dialog {
    padding: 0px 10px;
    top: 55%;
    max-height: 100%;
    /* overflow: auto; */
  }
  .modal-content {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .quiz_info_txt h4 {
    font-size: 18px;
    line-height: 26px;
  }
  .sign_acc_head p {
    display: none;
  }
  .portfolio_consti_section .row .consti-table:nth-child(even) .port_cons_block,
  .portfolio_consti_section .row .consti-table:nth-child(odd) .port_cons_block {
    padding: 0px !important;
  }
  .graph_theme_block {
    padding: 30px !important;
  }
  .dashboard_menu {
    position: fixed;
    z-index: 1111;
    background: #1f1f43;
    right: 0;
    top: 182px;
    width: 300px;
    bottom: 0px;
    height: 100%;
    overflow: auto;
    padding: 20px 30px;
    display: block !important;
  }
  body .dashboard_menu_hide {
    display: none !important;
  }
  .menu_container > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard_menu ul {
    gap: 0 !important;
    align-items: flex-start !important;
  }
  .dashboard_user_name {
    padding: 20px 0px !important;
    align-items: flex-start !important;
    flex-direction: column;
    gap: 15px !important;
  }
  .dashboard_user_name h4 {
    font-size: 24px !important;
  }
  .dashboard_user_name > .slide_nav_section {
    padding: 6px 10px !important;
  }
  .dashboard_user_name p {
    margin-left: 5px !important;
  }
  .rest_block h4 {
    width: 100% !important;
  }
  .dashboard_card {
    margin-bottom: 15px;
  }
  .dashboard_card1 {
    padding: 30px 45px !important;
    flex-direction: column;
  }
  .holding_table thead tr th {
    font-size: 16px !important;
    padding: 20px !important;
  }
  .holding_table tbody tr td {
    padding: 20px !important;
  }
  .rest_block img {
    width: 100% !important;
  }
  .compare_value_block {
    flex-wrap: wrap;
    gap: 15px;
    flex-direction: column;
    padding: 25px !important;
  }
  .rest_block h4 {
    font-size: 20px !important;
    line-height: 36px !important;
  }
  .theme_select {
    margin-bottom: 15px;
  }
  .theme_select {
    gap: 20px !important;
  }
  .mob_dashboard_menu .dashboard_menu ul li button {
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
  }
  .portfolio_graph_section {
    padding: 0px 0px 50px 0px !important;
  }
  .portfolio_line_graph_block {
    overflow: auto;
  }
  .portfolio_line_graph_block .line__chart_block {
    min-width: 670px;
  }
  .portfolio_line_graph_block .line__chart_block svg text {
    font-size: 9px !important;
  }
}
@media (min-width: 405px) and (max-width: 767px) {
  .risk_outerLine_btn {
    font-size: 14px;
    gap: 8px;
    padding: 10px 10px;
  }
  .modal-content {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .mob_dashboard_menu .menu_icon {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .question_content {
    width: 100%;
  }
  .modal-content {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .quiz_info_txt h4 {
    font-size: 18px;
    line-height: 26px;
  }

  .label_width {
    width: 215px;
  }
  .start_block {
    margin: 40px 0px 15px !important;
  }
  .sign_right_block {
    width: 100%;
  }
  .sign_right_card {
    padding: 30px 30px;
  }
  .sign_right_otp > .sign_right_card {
    width: 100%;
  }
  .register_section {
    padding-bottom: 30px;
  }
  .graph_portfolio_block .graph_block {
    height: 100%;
  }
  .graph_portfolio_block .graph_table_block {
    height: 100%;
    margin-top: 20px;
  }
  .already_usr p > span {
    display: none;
  }
  .quiz_pick_section {
    padding-bottom: 30px;
  }
  .dashboard_menu ul {
    gap: 25px !important;
  }
  .dashboard_card {
    margin-bottom: 15px;
  }
  .rest_block h4 {
    width: 100% !important;
    font-size: 22px !important;
  }
  .holding_table thead tr th {
    font-size: 16px !important;
    padding: 20px !important;
  }
  .holding_table tbody tr td {
    padding: 20px !important;
  }
  .dashboard_user_name {
    padding: 20px 0px !important;
  }
  .port_cons_list li div:nth-child(1) {
    flex: 1;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .dashboard_menu ul {
    gap: 60px !important;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .theme_select {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px !important;
  }
  .theme_select img {
    flex: 1;
  }
  .port_cons_list li div:nth-child(1) {
    flex: 1;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .theme_select {
    height: 220px;
    gap: 20px !important;
    padding: 15px !important;
  }
  .theme_select p {
    font-size: 15px !important;
  }
}
@media (min-width: 992px) and (max-width: 1500px) {
  .register_section {
    padding-bottom: 30px;
  }
  .pie_chart_section {
    width: 370px !important;
    right: 50px;
  }
  .risk_content_block ul {
    padding: 25px !important;
  }
}
@media (min-width: 992px) and (max-width: 1139px) {
  .pie_chart_section {
    width: 400px !important;
    right: 50px;
  }
}

/* Graph Page Design */

.portfolio_theme_section {
  background: #f9f9fb;
  padding: 35px 0px;
}

.primary_head h4 {
  font-size: 28px;
  line-height: 38px;
  color: #1f1e21;
  font-weight: bold;
  margin: 0;
}
.primary_head p {
  font-size: 16px;
  margin: 0px;
  line-height: 24px;
  color: #6b6c78;
}

.graph_theme_block {
  background: #e2e2ef;
  border-radius: 6px;
  padding: 30px 50px 30px 30px;
}

/* custom radio */

.customRadio .btn-radio {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: block;
}
@media screen and (max-width: 480px) {
  .customRadio .btn-radio {
    display: flex;
  }
  .customRadio .btn-radio span {
    flex: 1;
  }
}
.customRadio .btn-radio svg {
  fill: none;
  vertical-align: middle;
}
.customRadio .btn-radio svg circle {
  stroke-width: 1;
  stroke: #c8ccd4;
}
.customRadio .btn-radio svg path {
  stroke: #de6b7d;
}
.customRadio .btn-radio svg path.inner {
  stroke-width: 6;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
}
.customRadio .btn-radio svg path.outer {
  stroke-width: 1;
  stroke-dasharray: 57;
  stroke-dashoffset: 57;
}
.customRadio .btn-radio input {
  display: none;
}
.customRadio .btn-radio input:checked + svg path {
  transition: all 0.4s ease;
}
.customRadio .btn-radio input:checked + svg path.inner {
  stroke-dashoffset: 38;
  transition-delay: 0.3s;
}
.customRadio .btn-radio input:checked + svg path.outer {
  stroke-dashoffset: 0;
}
.customRadio .btn-radio span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-size: 16px;
  color: #63637e;
}
.radio-inline {
  display: flex;
  gap: 25px;
}
.customRadio .btn-radio input:checked ~ span {
  color: #de6b7d;
}
.radio_head h4 {
  font-size: 18px;
  color: #1f1e21;
  margin: 0px;
}
.radio_head {
  margin-bottom: 20px;
}

.theme_head h4 {
  color: #5f5a69;
  font-size: 16px;
  margin-bottom: 20px;
}
.theme_select_block {
  margin-top: 30px;
}

.theme_select_block .pick_section label {
  flex-direction: row !important;
  padding: 22px 35px 22px 24px;
  width: 100%;
}
.theme_select_block .pick_section label span.text {
  font-size: 16px;
  font-weight: 600;
  margin-left: 15px;
  color: #313153;
}

.theme_select_block .checkbox-booking:checked + label .text {
  color: red;
}
.theme_select_block .checkbox-booking:checked + label::before {
  z-index: 0;
}
.bg_transparent {
  background: transparent !important;
}

.portfolio_graph_section {
  padding: 80px 0px 50px 0px;
}
.portfolio_graph_section_dashboard {
  padding: 20px 0px 50px 0px;
}
.port_cons_head h3 {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.color_equity-dm {
  border-bottom: 2px solid #5b5f93;
}
.color_commodities {
  border-bottom: 2px solid #d95e73;
}
.color_cash {
  border-bottom: 2px solid #e8b23e;
}
.color_fixedincome-corp {
  border-bottom: 2px solid #646cce;
}
.color_fixedincome-govt {
  border-bottom: 2px solid #a355c9;
}
.color_equity-em {
  border-bottom: 2px solid #9194c4;
}
.color_reit {
  border-bottom: 2px solid #dbddff;
}

.port_cons_list {
  list-style: none;
  padding: 0;
}
.port_cons_list li {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
}
.port_cons_list li div h4 {
  font-size: 16px;
  color: var(--black);
  margin: 0px;
}
.port_cons_list li div span {
  display: inline-block;
  color: #bcbcbc;
  font-size: 14px;
}
.port_cons_list li div p {
  font-size: 18px;
  color: var(--black);
  margin: 0px;
  font-weight: bold;
}
.port_cons_block {
  margin-bottom: 50px;
}
.portfolio_consti_section .row .consti-table:nth-child(odd) .port_cons_block {
  padding-right: 50px;
}
.portfolio_consti_section .row .consti-table:nth-child(even) .port_cons_block {
  padding-left: 50px;
}
.portfolio_line_graph_block .tseting123 {
  background: #f9f9fb;
  border-radius: 6px;
  height: 390px;
  width: 100%;
}
.risk_slider_section {
  background: #1f1f43;
  border-radius: 6px;
  padding: 95px 0px 30px;
}

.risk_slider_section .splide__list li.splide__slide {
  border-radius: 6px;
  background: rgb(255, 255, 255);
  /* opacity: 0.4; */
}

.risk_slider_section .splide__list li.splide__slide.is-active {
  background: rgb(255, 255, 255);
  /* opacity: 1; */
}
.risk_slide_head h4 {
  color: #1f1e21;
  font-weight: bold;
  line-height: 38px;
  font-size: 28px;
  text-align: center;
  width: 670px;
}
.risk_slide_head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.graph_img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.risk_content_block {
  margin-top: 30px;
}
.risk_content_block > p {
  font-size: 16px;
  line-height: 24px;
  color: #6b6c78;
  margin: 0px 0px 5px;
  padding: 0px 10rem 0px 3rem;
}
.risk_content_block a {
  color: #302f3e;
  text-decoration: underline !important;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 10rem 0px 3rem;
}
.risk_content_block button {
  color: #302f3e;
  text-decoration: underline !important;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 10rem 0px 3rem;
}
.risk_content_block ul {
  padding: 0px;
  list-style: none;
  background: #f8f8ff;
  border-radius: 6px;
  padding: 25px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.risk_content_block ul li p {
  font-size: 14px;
  color: #b8b8d3;
  margin: 0px 0px 10px;
}
.risk_content_block ul li h4 {
  margin: 0px;
  display: flex;
  align-items: center;
  color: var(--black);
  font-size: 20px;
}
.risk_content_block ul li h4 img {
  margin-right: 10px;
}

.splide__pagination {
  bottom: unset;
  top: 0px;
  display: hidden;
}

.splide__pagination li button {
  background: #0e0e36;
  width: 154px;
  height: 53px;
  border-radius: 0px !important;
  margin: 0px;
}
.splide__pagination li button:hover {
  background: #ffffff;
}
.splide__pagination__page.is-active {
  background: #ffffff;
}
.splide__slider__block {
  padding: 0px;
}
.splide__track {
  position: relative;
  z-index: 11;
}
.splide__arrow {
  z-index: 12;
  width: 64px;
  height: 64px;
  background: #d95e73;
  border-radius: 50%;
}
.splide__arrow svg {
  fill: #ffffff;
  width: 30px;
  height: 30px;
}
.splide__arrow:hover svg {
  fill: var(--primar_color) !important;
}

.user_qus_section {
  display: grid;
  height: calc(90vh - 100px);
  overflow-y: auto;
}
.graph_layout {
  display: block !important;
}
.risk_graph_section {
  height: calc(90vh - 100px);
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.outer_primary_btn {
  background: transparent !important;
  border: 1px solid #d85e72;
  color: #d85e72;
  padding: 12px 55px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px;
}
.custom_btn {
  margin-top: 40px;
}

.slide_nav_section .slide_circle_indicate span {
  border: 1px solid #bababa;
  border-radius: 3px;
  width: 6px;
  height: 9px;
  display: inline-block;
  margin-right: 2px;
}

.low_slide .slide_circle_indicate span:nth-child(1) {
  background: #bababa;
}
.medium_slide .slide_circle_indicate span:nth-child(1),
.medium_slide .slide_circle_indicate span:nth-child(2) {
  background: #bababa;
}
.high_slide .slide_circle_indicate span {
  background: #bababa;
}

button.is-active .medium_slide .slide_circle_indicate span:nth-child(1),
button.is-active .medium_slide .slide_circle_indicate span:nth-child(2) {
  background: #f0cd80 !important;
  border: 1px solid #f0cd80;
}
button.is-active .low_slide .slide_circle_indicate span:nth-child(1) {
  background: #f0cd80;
  border: 1px solid #f0cd80;
}
button.is-active .high_slide .slide_circle_indicate span {
  background: #f0cd80;
  border: 1px solid #f0cd80;
}

.chart_risk.medium_slide .slide_circle_indicate span:nth-child(1),
.chart_risk.medium_slide .slide_circle_indicate span:nth-child(2) {
  background: #f0cd80 !important;
  border: 1px solid #f0cd80;
}
.chart_risk.low_slide .slide_circle_indicate span:nth-child(1) {
  background: #f0cd80;
  border: 1px solid #f0cd80;
}
.chart_risk.high_slide .slide_circle_indicate span {
  background: #f0cd80;
  border: 1px solid #f0cd80;
}

button.is-active .slide_nav_section p {
  color: var(--black);
}

.slide_nav_section p {
  margin: 0px 0px 0px 6px;
  font-size: 16px;
  color: #bababa;
}
.slide_nav_section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invest_btn_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invest_details_block {
  display: flex;
  align-items: center;
}
.invest_details_block img {
  margin-right: 15px;
}
.invest_details_block h4 {
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  color: #6b6c78;
}
.invest_details_block h4 span {
  color: #d95e73;
  font-weight: 600;
}
.invest_details_block p {
  margin: 0px;
  color: #6b6c78;
  font-size: 14px;
}
.invest_details_block p strong {
  color: var(--black);
}
.invest_btn_block button {
  padding: 14px 61px;
  font-size: 14px !important;
  letter-spacing: 0.25px;
}
.portfolioSec_section {
  width: 100%;
}

.dashboard_head {
  background: #1f1f43;
}
.dashboard_user_name {
  display: flex;
  align-items: center;
  padding: 40px 0px;
}
.dashboard_user_name h4 {
  margin: 0px 30px 0px 0px;
  font-size: 28px;
  line-height: 26px;
  color: var(--white);
  text-transform: capitalize;
  font-weight: bold;
}
.dashboard_user_name p {
  margin: 0px;
  font-weight: 600;
  color: var(--white);
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  margin-left: 15px;
}
.dashboard_user_name > .slide_nav_section {
  border: 1px solid #f0cd80;
  border-radius: 6px;
  padding: 6px 15px;
}
.dashboard_user_name > .medium_slide .slide_circle_indicate span:nth-child(1),
.dashboard_user_name > .medium_slide .slide_circle_indicate span:nth-child(2) {
  background: #f0cd80 !important;
  border: 1px solid #f0cd80;
}
.dashboard_user_name > .high_slide .slide_circle_indicate span {
  background: #f0cd80 !important;
  border: 1px solid #f0cd80;
}
.dashboard_user_name > .low_slide .slide_circle_indicate span:nth-child(1) {
  background: #f0cd80 !important;
  border: 1px solid #f0cd80;
}
.dashboard_menu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.dashboard_menu ul {
  padding: 0px;
  list-style: none;
  display: flex;
  margin: 0px;
  gap: 94px;
  align-items: center;
}
.dashboard_menu ul li button {
  font-size: 18px;
  color: var(--white);
  padding-bottom: 20px;
  display: inline-block;
  margin: 0px !important;
  background: transparent;
  border-bottom: 4px solid transparent !important;
  border: 1px solid transparent;
}
.dashboard_menu ul li.link-active button {
  border-bottom: 4px solid #d95e73 !important;
  color: #d95e73;
  border: 1px solid transparent;
}

.recommend_txt {
  position: absolute;
  top: 0;
  right: 0;
  background: #dbdbff;
  box-shadow: 0px 2px 6px #b8b8ce5d;
  border: 1px solid #000000;
  border-radius: 0px 6px;
  padding: 7px 24px;
}
.risk_slide_block {
  position: relative;
  padding: 40px;
}

/* ----------------------------------- New Responsive ----------------------------- */

@media screen and (max-width: 1024px) {
  .risk_content_block > p {
    padding: 0;
  }
  .risk_content_block a {
    padding: 0;
  }
  .splide__arrow {
    z-index: 12;
    width: 40px;
    height: 40px;
  }
  .splide__arrow svg {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 768px) {
  .portfolio_consti_section .row .consti-table .port_cons_block {
    padding: 0 10px !important;
  }
  .pie-Chart > div {
    max-height: 200px;
  }
  .risk_slide_block {
    padding: 50px 20px 30px;
  }
  .risk_slide_head h4 {
    width: 100% !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .risk_content_block ul {
    flex-wrap: wrap;
    gap: 20px;
  }
  .recommend_txt {
    padding: 6px 15px !important;
  }
  .recommend_txt span {
    font-size: 14px;
  }
  .port_cons_list li {
    gap: 50px;
    flex-wrap: wrap;
  }
  .port_cons_list li div:nth-child(1) {
    flex: 1;
  }
  .primary_head h4 {
    font-size: 24px !important;
  }
  .invest_btn_block {
    flex-direction: column;
    gap: 20px;
  }
  .customRadio .note_txt {
    text-align: left !important;
  }
  .graph_theme_block .pick_section div {
    flex: unset !important;
  }
  .theme_select_block .pick_section label {
    justify-content: flex-start !important;
  }
  .invest_btn_block button {
    width: 100%;
  }
  .invest_details_block h4 {
    font-size: 14px !important;
  }
  .user_qus_section {
    padding: 40px 0px;
  }
  .graph_layout {
    padding: 0px 0px 40px !important;
  }
}

@media screen and (max-width: 520px) {
  .splide__pagination li button {
    width: 100%;
    height: 100%;
    padding: 15px 12px;
  }
  .splide__arrow {
    width: 30px;
    height: 30px;
  }
  .splide__arrow svg {
    width: 15px;
    height: 15px;
  }
  .splide__arrow--next {
    right: 0.5em;
  }
  .splide__arrow--prev {
    left: 0.5em;
  }
  .risk_slider_section .splide__list li.splide__slide {
    padding: 0px;
  }
  .risk_content_block ul {
    padding: 15px 20px;
  }
  .quiz_btn button {
    padding: 10px 30px;
  }
  .quiz_btn_section {
    padding: 15px 0px 20px;
  }
}

@media (min-width: 320px) and (max-width: 426px) {
  .pie_chart_section {
    width: 350px !important;
    height: 255px !important;
    right: 40px;
  }
}
@media (min-width: 426px) and (max-width: 520px) {
  .pie_chart_section {
    width: 360px !important;
    height: 255px !important;
    right: 10px;
  }
}

.graph_theme_block .pick_section div {
  flex: 1;
}

.pie_chart_text {
  inset: 0px 0px 0px 0px !important;
}
.pie_chart_text p {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px !important;
  color: #6b6c78 !important;
}
.pie_chart_text h4 {
  margin: 0px !important;
  color: #5c5871 !important;
  font-size: 16px !important;
}
.chart_risk {
  gap: 8px;
}

.chart_risk .slide_circle_indicate span {
  border: 1px solid #f0cd80 !important;
}
.pie_chart_section {
  margin: 0 auto;
}

.avatar_img p {
  display: block !important;
}
.start_img {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: bounces;
  animation-timing-function: ease-in-out;
}
@keyframes bounces {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

.help_btn {
  margin-bottom: 15px;
}

.help_btn a img {
  margin-right: 10px;
}

.dashboard_card {
  background: var(--white);
  padding: 45px;
}
.dashboard_card_img {
  width: 50px;
  height: 52px;
  margin-bottom: 24px;
}
.dashboard_card_img img {
  max-width: 100%;
  object-fit: contain;
}
.dashboard_card_content p {
  font-size: 18px;
  line-height: 24px;
  color: #a8a9b1;
  margin: 0px 0px 20px;
}
.dashboard_card_content h4 {
  font-size: 26px;
  line-height: 38px;
  font-weight: bold;
  color: var(--black);
  display: flex;
  align-items: center;
  margin: 0px;
}
.dashboard_card_content h4 span {
  color: #1f1e2199;
}

.dashboard_card_content h4 img {
  margin-left: 10px;
}
.overview_section {
  padding: 25px 0px 35px;
  background: #ededf4;
}

.dashboard_card1 {
  background: var(--white);
  padding: 10px 60px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
}
.dashboard_card1_img {
  width: 335px;
  height: 185px;
}
.dashboard_card1_img img {
  max-width: 100%;
  object-fit: contain;
}
.dashboard_card1_content h4 {
  font-size: 20px;
  color: var(--black);
  font-weight: bold;
  margin: 0px 0px 10px;
}
.dashboard_card1_content p {
  font-size: 16px;
  color: #6b6c78;
  line-height: 26px;
  margin-bottom: 15px;
}
.dashboard_card1_content a {
  font-size: 16px;
  color: #d95e73;
  font-weight: 600;
  line-height: 28px;
  display: inline-block;
}

.update_txt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.update_txt p {
  font-size: 14px;
  color: #a8a9b1;
  margin: 0px 8px 0px 0px;
}

.holding_section {
  padding: 40px 0px;
}
.holding_txt p {
  font-size: 16px;
  color: #6b6c78;
  line-height: 26px;
  margin-bottom: 15px;
}

.holding_table thead tr th {
  color: var(--black);
  font-weight: bold;
  font-size: 18px;
  padding: 20px 3px;
  text-align: center;
  position: sticky;
  top: 0;
  background: #fff;
  white-space: nowrap;
}
.holding_table tbody tr td {
  padding: 20px 8px;
  font-size: 16px;
  color: var(--black) !important;
  text-align: center;
  font-weight: 500;
}
.holding_table tbody tr td:nth-child(1) {
  color: #d95e73 !important;
  text-align: left;
  max-width: 200px;
  padding-left: 25px !important;
}
.holding_table thead tr th:nth-child(1) {
  text-align: left;
  padding-left: 25px !important;
}
.holding_table_res {
  height: 500px;
  overflow: auto;
}
.compare_section {
  padding: 50px 0px;
}
.compare_value_block {
  background: #f9f9fb;
  border-radius: 10px;
  padding: 32px 48px 32px 25px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.compare_value_content {
  display: flex;
  align-items: center;
}
.compare_value_content img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-right: 15px;
}
.compare_value_content div h4 {
  color: #1f1e21;
  font-weight: bold;
  font-size: 24px;
  margin: 0px 0px 5px;
}
.compare_value_content div p {
  color: #1f1e21;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
}
.compare_value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.compare_value h3 {
  font-weight: 800;
  font-size: 28px;
  color: #d95e73;
  margin: 0px;
}
.compare_value_block div {
  flex: 1;
}
.compare_more_txt p {
  font-size: 14px;
  color: #1f1e21;
  margin-bottom: 0px;
}
.compare_more_txt p a {
  font-size: 16px !important;
  color: #302f3e !important;
  font-weight: 500;
  text-decoration: underline !important;
  margin-left: 20px;
  display: inline-block;
}
.compare_portfolio_list {
  margin-top: 25px;
}
.compare_portfolio_list ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.compare_portfolio_list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(112, 112, 112, 0.13);
  padding: 15px 30px 15px 20px;
}
.compare_portfolio_list ul li:last-child {
  border-bottom: none !important;
}
.compare_portfolio_list ul li .compare_portfolio_list_img img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.compare_portfolio_list ul li .compare_portfolio_list_img p {
  color: #272727;
  font-size: 16px;
  margin: 0px;
}
.compare_portfolio_list ul li div h4 {
  font-size: 18px;
  color: var(--black);
  font-weight: 600;
  margin: 0px;
}
.compare_portfolio_list ul li div h4 span {
  color: #1f1e2199;
}
.compare_portfolio_list ul li .compare_portfolio_list_img {
  display: flex;
  align-items: center;
  gap: 24px;
}
.rest_block {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rest_block img {
  width: 372px;
  height: 184px;
  object-fit: contain;
}
.rest_block h4 {
  color: #1f1e21;
  font-size: 28px;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  width: 845px;
  margin: 15px 0px 30px;
}
.rest_block button {
  padding: 15px 80px;
}
.reset_section {
  padding: 80px 0px 50px;
}

.dashboard_header {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.review_txt {
  font-size: 24px !important;
  line-height: 36px;
  color: #302f3e !important;
  font-weight: 500 !important;
  width: 100% !important;
}

button:disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.rest_block img {
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: fly;
  animation-timing-function: ease-in-out;
}
@keyframes fly {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(10deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.portfolio_line_graph_block .line__chart_block {
  background: #f9f9fb;
  border-radius: 6px;
  height: 390px;
  width: 100%;
}

.line_tooltip_txt p {
  margin: 0px;
  font-weight: 600;
}

.line_tooltip_txt p span {
  color: var(--black);
}

.line_tooltip_txt p {
  color: #d95e73;
}
.line_tooltip_txt .benchMark_label p {
  color: #543979;
}

.pie_chart_section {
  text-align: center;
  position: relative;
  width: 400px;
  height: 310px;
}
.pie_chart_text {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
}
.pie_chart_text p {
  font-size: 14px;
  margin: 0;
}

.theme_select {
  background: #ffffff;
  border: 2px solid #d8d8ff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 20px 25px;
}
.theme_select p {
  margin: 0px;
  font-size: 16px;
  color: #313153;
  font-weight: 600;
}
.quiz_info_popup .img_placeholder {
  width: 300px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: #ff0000;
  font-size: 12px;
}
.help_popup_body {
  padding: 40px;
  position: relative;
}
.help_popup_section h4 {
  font-size: 20px;
  line-height: 24px;
  color: #1f1e21;
  font-weight: 600;
  margin: 0px 0px 30px;
}
.help_popup_section ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.help_popup_section ul li {
  background: #f8f8ff;
  border-radius: 6px;
  font-size: 16px;
  line-height: 26px;
  color: #6b6c78;
  margin-bottom: 10px;
  padding: 18px 24px;
}
.help_popup_section button {
  padding: 10px 70px;
  margin-top: 50px;
  float: right;
}
.help_modal_close {
  position: absolute;
  top: 30px;
  right: 20px;
}
.help_btn:hover a {
  color: var(--primar_color);
}

@media (min-width: 1501px) {
  .about_pie_chart .pie_chart_section {
    right: 40px;
  }
}
.facebook {
  border: none;
}
.widget_popup_body {
  padding: 30px;
}
.widget_popup_section .widget_logo {
  width: 110px;
  margin: 0 auto 15px;
}
.widget_popup_section .widget_logo img {
  max-width: 100%;
  object-fit: contain;
}
.widget_popup_body .help_modal_close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.widget_popup_section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_popup_section ul li {
  background: #f8f8ff;
  border-radius: 6px;
  font-size: 16px;
  color: #6b6c78;
  line-height: 26px;
  padding: 18px 24px 18px 35px;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
}
.widget_popup_section ul li::before {
  content: url(../public/images/icons/list-arrow.svg);
  position: absolute;
  left: 10px;
  top: 20px;
}
.custom_btn button:hover {
  background: var(--primar_color) !important;
  color: var(--white);
  transition: all 0.5s;
}
.custom_btn button {
  transition: all 0.5s;
}
.tell_more_btn {
  color: #302f3e;
  text-decoration: underline !important;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 10rem 0px 3rem;
}
.compare_more_txt .tell_more_btn {
  padding: 0px 0px 0px 10px !important;
}
.facebook.metro {
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 18px 34px;
  color: var(--para_color2);
  font-size: 14px;
  transition: all 0.5s;
  height: 60px;
  background: transparent;
}
.facebook.metro:hover {
  border: 1px solid var(--border_color1);
  background: #fff8f9;
  transition: all 0.5s;
}

.note_txt p button.widget_modal_btn {
  font-size: 13px;
  color: #1f1e21;
  font-weight: bold;
  text-decoration: underline !important;
  margin-left: 10px;
  padding: 0px !important;
}
@media (max-width: 1024px) {
  .risk_content_block button {
    padding: 0px !important;
  }
}

.widget_popup_section .fst-italic {
  font-size: 16px;
  color: #6b6c78;
  line-height: 26px;
  font-weight: 600;
  position: relative;
}
.popup_bottom_msg p {
  color: #6b6c78;
  font-size: 14px;
  margin: 0px;
  text-align: center;
}
.react-bootstrap-table table {
  table-layout: auto;
}
.disclaimer_popup {
  align-items: flex-end;
  justify-content: flex-end;
}
.popup_class {
  max-height: calc(100vh - 0px);
}


.title_color{

color:var(--border_color1) !important;
}
